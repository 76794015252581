//import React from 'react'
import React, { Fragment } from 'react';
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
//import Layout from '../components/layout'

import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { theme } from 'common/src/theme/agencyModern';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from '../containers/OnePageTemplate/agencyModern.style';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import Navbar from '../containers/OnePageTemplate/Navbar';
import Banner from '../containers/OnePageTemplate/Banner';
import Services from '../containers/OnePageTemplate/Services';
import Features from '../containers/OnePageTemplate/Features';
import WorkHard from '../containers/OnePageTemplate/WorkHard';
import UltimateFeature from '../containers/OnePageTemplate/UltimateFeature';
import Customer from '../containers/OnePageTemplate/Customer';
import News from '../containers/OnePageTemplate/News';
import Subscribe from '../containers/OnePageTemplate/Subscribe';
import ContactForm from '../containers/OnePageTemplate/ContactForm';
import GlobalBannerAd from '../containers/OnePageTemplate/GlobalBannerAd';
import Footer from '../containers/OnePageTemplate/Footer';
import Testimonial from '../containers/OnePageTemplate/Testimonial'
import Benefits from '../containers/OnePageTemplate/Benefits'
import PortfolioShowcase from '../containers/OnePageTemplate/PortfolioShowcase'
import SEO from '../components/seo';

const navData = {
  leftMenuItems: [
    {
      label: 'Inicio',
      path: '#home',
      offset: '70',
    },
    {
      label: 'Servicios',
      path: '#services',
      offset: '70',
    },
    {
      label: 'Testimonios',
      path: '#testimonials',
      offset: '70',
    },
    {
      label: 'Diferencias',
      path: '#benefits',
      offset: '70',
    },
    {
      label: 'Contacto',
      path: '#contact',
      offset: '70',
    } 
  ],
  mobileMenuItems: [
    {
      label: 'Inicio',
      path: '#home',
      offset: '70',
    },
    {
      label: 'Servicios',
      path: '#services',
      offset: '70',
    },
    {
      label: 'Testimonios',
      path: '#testimonials',
      offset: '70',
    },
    {
      label: 'Diferencias',
      path: '#benefits',
      offset: '70',
    },
    {
      label: 'Contacto',
      path: '#contact',
      offset: '70',
    } 
  ],
};

class OnePageDefaultTemplate extends React.Component {
  
  render() {
    const site = get(this.props, 'data.contentfulSite');
    const siteAds = get(this.props, 'data.allContentfulSiteAd.edges');
    const siteTitle = get(this.props, 'data.site.siteMetadata.title');

    return (
      <ThemeProvider theme={theme}>
      <Fragment>
        <SEO title={site.name} />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar navData={navData} companyLogo={site.companyLogo} companyName={site.name} />
            </DrawerProvider>
          </Sticky>
          <Banner backgroundImage={site.backgroundImage} backgroundColor={site.backgroundImageColor} headlineText={site.headlineText} introText={site.introductionText.childMarkdownRemark.html} />          
          {/*<PortfolioShowcase servicesOrProducts={site.featuredProductsOrServices} titleText={site.featuredProductsOrServicesTitleText} subtitleText={site.featuredProductsOrServicesSubtitleText} />*/}
          <Services servicesOrProducts={site.featuredProductsOrServices} titleText={site.featuredProductsOrServicesTitleText} subtitleText={site.featuredProductsOrServicesSubtitleText} />          
          <Testimonial confiablePageUrl={site.confiablePageUrl} />         
          {/* <Subscribe /> */}
          <GlobalBannerAd bannerObj={siteAds[0].node} />
          <Benefits featureItems={site.features} featureTitleText={site.featuresTitleText} featureSubtitleText={site.featuresSubtitleText} />          
          <ContactForm emailText={site.contactEmailAddress} phoneText={site.contactPhoneNumber} 
              addressInfoObj={{ addressLine1 : site.addressLine1, addressLine2 : site.addressLine2, addressCity : site.addressCity, addressState : site.addressState, addressCountry : site.addressCountry, addressZipCode : site.addressZipCode}} />
          <Footer companyLogo={site.companyLogo} facebookPageUrl={site.facebookPageUrl} confiablePageUrl={site.confiablePageUrl} />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
    )
  }
}

export default OnePageDefaultTemplate

export const pageQuery = graphql`
  query SiteSingle($siteDomainKey: String!) {
    allContentfulSiteAd {
      edges {
        node {
          name
          bannerImage {
            fluid(maxWidth: 1024) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          bannerImageMobile {
            fluid(maxWidth: 728) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    contentfulSite(domain: { eq: $siteDomainKey }) {
      name
      domain
      companyLogo {      
        fluid(maxHeight: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      heroImage {
        fluid(maxWidth: 2600) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      backgroundImage {
        fluid(maxWidth: 2600) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      backgroundImageColor
      headlineText
      introductionText {
        childMarkdownRemark {
          html
        }
      }
      featuredProductsOrServicesTitleText
      featuredProductsOrServicesSubtitleText
      featuredProductsOrServices 
      {
        ... on ContentfulService {
            id
            name
            description {
              childMarkdownRemark {
                html
              }
            }
            featuredImage {
              fluid(maxWidth: 600, maxHeight: 450) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            priceDescription
          }        
      }
      facebookPageUrl
      instagramPageUrl
      confiablePageUrl
      contactEmailAddress
      contactPhoneNumber
      contactWhatsAppNumber
      addressLine1
      addressLine2
      addressCity
      addressState
      addressCountry
      addressZipCode
      featuresTitleText
      featuresSubtitleText
      features {
        name
        shortDescription
        iconKey
      }
    }
  }
`
