import React from 'react';
import PropTypes from 'prop-types';
import Container from 'common/src/components/UI/Container';
import CheckBox from 'common/src/components/Checkbox';
import Button from 'common/src/components/Button';
import Input from 'common/src/components/Input';
import Image from 'common/src/components/Image';
import Text from 'common/src/components/Text';
import Box from 'common/src/components/Box';

import SectionWrapper, {  
  Content,
  MobileBannerWrapper,
  DesktopBannerWrapper
} from './contactForm.style';

const GlobalBannerAd = ({ row, col, bannerObj }) => {
    return (
    <SectionWrapper id="bannerAd">
      <Container>   
        <DesktopBannerWrapper>
            <Image
                    fluid={
                    (bannerObj.bannerImage !== null) | undefined
                        ? bannerObj.bannerImage.fluid
                        : {}
                    }      
                    alt="Banner Ad"                      
                    src={bannerObj.bannerImage.fluid.src}
                />
        </DesktopBannerWrapper>     
        <MobileBannerWrapper>
            <Image
                    fluid={
                    (bannerObj.bannerImageMobile !== null) | undefined
                        ? bannerObj.bannerImageMobile.fluid
                        : {}
                    }    
                    alt="Banner Ad"            
                    src={bannerObj.bannerImageMobile.fluid.src}
                />   
        </MobileBannerWrapper>
      </Container>      
    </SectionWrapper>
    );
}

// DonateSection style props
GlobalBannerAd.propTypes = {
    row: PropTypes.object,
    col: PropTypes.object,
    textStyle: PropTypes.object,
  };
  
  // DonateSection default style
GlobalBannerAd.defaultProps = {
    // DonateSection row default style
    row: {
      flexBox: true,
      flexWrap: 'wrap',
      ml: '-15px',
      mr: '-15px',
    },
    // DonateSection col default style
    col: {
      width: ['100%', '50%', '50%'],
      pl: '15px',
      pr: '15px',
      mb: '30px',
    },
  };

export default GlobalBannerAd;
