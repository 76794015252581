import React from 'react';
import PropTypes from 'prop-types';
import Container from 'common/src/components/UI/ContainerTwo';
import CheckBox from 'common/src/components/Checkbox';
import Button from 'common/src/components/Button';
import Input from 'common/src/components/Input';
import Image from 'common/src/components/Image';
import Text from 'common/src/components/Text';
import Box from 'common/src/components/Box';

import SectionWrapper, {
  FooterInner,
  Content,
  SubscriptionForm,
  SubmitContactForm,
  ContentArea,
  Heading
} from './contactForm.style';


const ContactForm = ({ row, col, emailText, phoneText, addressInfoObj }) => {
    return (
    <SectionWrapper id="contact">
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <ContentArea>
              <Heading>
                ¿Como te podemos <span>ayudar</span>?
              </Heading>
              <Text content="Sin ningún compromiso acercate a nosotros para que conozcas nuestros servicios. Estamos disponible por teléfono, email y chat." />
                <ul style={{ color : "white", marginLeft: "10px", lineHeight: "2"}}>
                    {emailText && <li><b>Email:</b> {emailText}</li>}
                    {phoneText && <li><b>Teléfono:</b> {phoneText}</li>}
                    <li><b>Horario:</b> 9:00 AM a 6:00 PM</li>
                    {addressInfoObj && 
                        <>
                        <li><b>Dirección:</b></li>
                        <li>{addressInfoObj.addressLine1}</li>
                        {addressInfoObj.addressLine2 && <li>{addressInfoObj.addressLine2}</li>}
                        <li>{addressInfoObj.addressCity}, {addressInfoObj.addressState}</li>
                        <li>{addressInfoObj.addressCountry} {addressInfoObj.addressZipCode} </li>
                        </>
                    }
                </ul>

            </ContentArea>
          </Box>
          <Box className="col" {...col}>
            <SubmitContactForm>
                <div>
                <Input
                    inputType="email"
                    placeholder="Email"
                    iconPosition="left"
                    aria-label="email"
                />

                <Input
                    inputType="text"
                    placeholder="Nombre(s)"
                    iconPosition="left"
                    aria-label="name"
                />

                <Input
                    inputType="textarea"
                    placeholder="Mensaje"
                    iconPosition="left"
                    aria-label="name"
                />

                <Button title="Subscribe" type="submit" />
                </div>
                {/*<CheckBox
                id="remember"
                htmlFor="remember"
                labelText="Don’t provide any promotional message."
                />*/}
            </SubmitContactForm>
          </Box>
          </Box>
          
      </Container>      
    </SectionWrapper>
    );
}

// DonateSection style props
ContactForm.propTypes = {
    row: PropTypes.object,
    col: PropTypes.object,
    textStyle: PropTypes.object,
  };
  
  // DonateSection default style
ContactForm.defaultProps = {
    // DonateSection row default style
    row: {
      flexBox: true,
      flexWrap: 'wrap',
      ml: '-15px',
      mr: '-15px',
    },
    // DonateSection col default style
    col: {
      width: ['100%', '50%', '50%'],
      pl: '15px',
      pr: '15px',
      mb: '30px',
    },
  };

export default ContactForm;
