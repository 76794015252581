import styled from 'styled-components';
import { rgba } from 'polished';
import { themeGet } from '@styled-system/theme-get';
import mapImage from 'common/src/assets/image/charity/map-alt.png';

const SectionWrapper = styled.div`
  width: 100%;
  padding: 130px 0 100px;
  margin: 0px 0;
  background-color: ${themeGet('colors.heading', '#060F1E')};  
  background-image: url(${mapImage});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  @media only screen and (max-width: 1440px) {
    margin: 70px 0;
    padding: 120px 0 90px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1360px) {
    margin: 50px 0;
    padding: 100px 0 70px;
    .row {
      > .col {
        &:first-child {
          width: 53%;
        }
        &:last-child {
          width: 47%;
        }
      }
    }
  }
  @media only screen and (max-width: 991px) {
    margin: 40px 0;
    padding: 80px 0 50px;
  }
  @media only screen and (max-width: 667px) {
    .row {
      > .col {
        width: 100%;
      }
    }
  }
`;

export const SubmitContactForm = styled.form`
  padding: 50px;
  border-radius: 10px;
  background-color: ${themeGet('colors.white', '#ffffff')};
  @media only screen and (max-width: 1440px) {
    padding: 50px 45px;
  }
  @media only screen and (max-width: 1360px) {
    padding: 40px 35px;
  }
  @media only screen and (max-width: 991px) {
    padding: 30px 20px;
  }
  @media only screen and (max-width: 667px) {
    max-width: 448px;
  }
  @media only screen and (max-width: 480px) {
    margin-top: 20px;
  }
  .field-wrapper {
    margin-right: 15px;
    margin-top: 24px;
    @media only screen and (max-width: 480px) {
      margin-right: 0px;
      margin-bottom: 15px;
    }
    input {
      background-color: #eff3f7;
      border: 0;
      font-family: DM Sans;
      font-size: 16px;
      min-height: 60px;
      padding: 24px 24px;
     
      ::placeholder {
        color: ${rgba('#02073E', 0.4)};
        opacity: 1; /* Firefox */
      }
      &:focus {
        border-color: #ff825c;
      }

      @media only screen and (max-width: 480px) {
        min-height: 50px;
      }
    }  
  }

  textarea {
    margin-right: 15px;
    margin-top: 24px;
    margin-bottom: 24px;
    @media only screen and (max-width: 480px) {
      margin-right: 0px;
      margin-bottom: 15px;
    }
    
    background-color: #eff3f7;
    border: 0;
    font-family: DM Sans;
    font-size: 16px;
    min-height: 60px;
    padding: 24px 24px;
    
    ::placeholder {
      color: ${rgba('#02073E', 0.4)};
      opacity: 1; /* Firefox */
    }
    &:focus {
      border-color: #ff825c;
    }

    @media only screen and (max-width: 480px) {
      min-height: 80px;
    }
  }

  .input_group {
    input {
      @media only screen and (max-width: 1360px) {
        width: calc(100% - 140px);
        padding: 0 15px;
        font-size: 15px;
        height: 56px;
      }
      @media only screen and (max-width: 991px) {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      @media only screen and (max-width: 480px) {
        width: calc(100% - 110px);
        margin-bottom: 10px;
      }
    }
    .select_wrapper {
      @media only screen and (max-width: 1360px) {
        width: 140px;
      }
      @media only screen and (max-width: 480px) {
        width: 110px;
      }
      .current_option {
        @media only screen and (max-width: 1360px) {
          height: 56px;
        }
        @media only screen and (max-width: 991px) {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
        .text {
          @media only screen and (max-width: 1360px) {
            font-size: 15px;
            margin-right: 10px;
          }
        }
        svg {
          @media only screen and (max-width: 1360px) {
            width: 12px;
            height: auto;
          }
        }
      }
      .dropdown {
        @media only screen and (max-width: 1360px) {
          top: 56px;
        }
        @media only screen and (max-width: 991px) {
          border-radius: 5px;
        }
      }
    }
  }

  .radio_group {
    margin-top: 50px;
    @media only screen and (max-width: 1440px) {
      margin-top: 45px;
    }
    @media only screen and (max-width: 1360px) {
      margin-top: 35px;
    }
    @media only screen and (max-width: 991px) {
      margin-top: 25px;
    }
    @media only screen and (max-width: 480px) {
      flex-direction: column;
      margin: 0;
    }

    label {
      @media only screen and (max-width: 1360px) {
        padding: 10px 15px;
      }
      @media only screen and (max-width: 480px) {
        width: 100%;
        margin: 10px 0;
      }
      &.active {
        border-color: ${themeGet('colors.primary', '#FCF22B')};
        background-color: ${themeGet('colors.primary', '#FCF22B')};
      }

      h4 {
        font-size: 20px;
        font-weight: 600;
        @media only screen and (max-width: 1440px) {
          font-size: 16px;
          margin-bottom: 5px;
        }
        @media only screen and (max-width: 1360px) {
          font-size: 15px;
        }
      }

      p {
        color: ${themeGet('colors.text', '#294859')};
        font-size: 14px;
        @media only screen and (max-width: 1360px) {
          font-size: 13px;
        }
      }
    }
  }
`;

export const Heading = styled.h2`
  font-size: 40px;
  line-height: 70px;
  font-weight: 300;
  margin: 0 0 20px;
  color: ${themeGet('colors.white', '#ffffff')};
  @media only screen and (max-width: 1440px) {
    font-size: 36px;
    line-height: 54px;
    margin: 0 0 27px;
  }
  @media only screen and (max-width: 1360px) {
    font-size: 34px;
    line-height: 52px;
    margin-bottom: 25px;
  }
  @media only screen and (max-width: 991px) {
    font-size: 30px;
    line-height: 46px;
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 28px;
    line-height: 40px;
  }

  span {
    color: ${themeGet('color.primary', '#FCF22B')};
  }
`;

export const ContentArea = styled.div`
  padding-right: 99px;
  @media only screen and (max-width: 1440px) {
    padding-right: 40px;
  }
  @media only screen and (max-width: 1360px) {
    padding-right: 0;
  }
  @media only screen and (max-width: 667px) {
    max-width: 465px;
  }

  > p {
    color: ${themeGet('colors.white', '#ffffff')};
    font-size: 18px;
    @media only screen and (max-width: 1440px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 1360px) {
      font-size: 15px;
    }
    @media only screen and (max-width: 991px) {
      line-height: 30px;
    }
  }
`;

export const FooterInner = styled.div`
  background-color: #ffffff;
  border-radius: 15px;
  padding: 60px;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1023px) {
    flex-direction: column;
  }
  @media only screen and (max-width: 600px) {
    padding: 35px;
  }
  > div {
    width: calc(100% / 2);
    @media only screen and (max-width: 1023px) {
      width: 100%;
    }
  }
`;

export const Content = styled.div`
  margin-right: 70px;
  @media only screen and (max-width: 1023px) {
    margin-right: 0;
  }
  h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 55px;
    letter-spacing: -0.5px;
    margin-bottom: 6px;
    @media only screen and (max-width: 600px) {
      font-size: 24px;
      line-height: 35px;
    }
  }
  p {
    color: #343d48;
    font-size: 16px;
    line-height: 30px;
  }
`;

export const SubscriptionForm = styled.div`
  > div {
    display: flex;
    @media only screen and (max-width: 480px) {
      flex-direction: column;
    }
  }
  @media only screen and (max-width: 480px) {
    align-items: center;
  }
  .reusecore__input {
    width: 100%;
  }
  .field-wrapper {
    margin-right: 15px;
    margin-top: 24px;
    @media only screen and (max-width: 480px) {
      margin-right: 0px;
      margin-bottom: 15px;
    }
    input {
      background-color: #eff3f7;
      border: 0;
      font-family: DM Sans;
      font-size: 16px;
      min-height: 60px;
      padding: 24px 24px;
     
      ::placeholder {
        color: ${rgba('#02073E', 0.4)};
        opacity: 1; /* Firefox */
      }
      &:focus {
        border-color: #ff825c;
      }

      @media only screen and (max-width: 480px) {
        min-height: 50px;
      }
    }
  }
  button {
    background-color: #ff825c;
    min-width: 150px;

    @media only screen and (max-width: 480px) {
      min-width: 100px;
    }
  }
  .reusecore__checkbox {
    margin-top: 10px;
    .reusecore__field-label {
      cursor: pointer;
      color: ${rgba('#9095ad', 0.9)};
      font-weight: 400;
      font-size: 14px;
    }
    .checkbox + div {
      background-color: #eff3f7;
      border: 0;
      &::after {
        top: 2px;
      }
    }
  }
`;

export default SectionWrapper;
