import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Container from 'common/src/components/UI/ContainerTwo';
import Image from 'common/src/components/Image';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import Box from 'common/src/components/Box';
import Button from 'common/src/components/Button';

import SectionWrapper, { SectionHeader, ServiceWrapper } from './service.style';
import {
  PortfolioShowcaseWrapper,
  PortfolioShowcaseItem,  
  BuiltWith
} from './portfolioShowcase.style';
// import data from 'common/src/data/AgencyModern';

const Services = ({ servicesOrProducts, titleText, subtitleText,
  secHeading,
  portfolioImage,
  portfolioDetails,
  titleStyle,
  detailsStyle,
}) => {  
  return (
    <SectionWrapper id="services">
      <Container>
        <Fade up delay={100}>
          <SectionHeader>
            <Heading 
              {...secHeading}
              content={titleText} />
            <Text content={subtitleText} />
          </SectionHeader>
        </Fade>
        <ServiceWrapper>
          
          {servicesOrProducts && servicesOrProducts.map((item, index) => {
            return (
              <Fade up delay={130 * item.id} key={`service-key-${item.id}`}>
                <FeatureBlock
                  key={`post_key-${index}`}
                  id={`post_id-${item.id}`}
                  className="service__item"
                  icon={
                    <Image
                      fluid={
                        (item.featuredImage !== null) | undefined
                          ? item.featuredImage.fluid
                          : {}
                      }
                      src={item.featuredImage.fluid.src}
                      alt={`Imagen ${item.id}`}
                      objectFit="cover"
                      className="service__image"
                    />
                  }                  
                  iconPosition="left"
                  title={<Heading as="h4" content={item.name} />}
                  description={<Text content={item.description.childMarkdownRemark.html.replace(/(<([^>]+)>)/gi, "")} />}
                />
              </Fade>
            );
          })}
        </ServiceWrapper>

        <PortfolioShowcaseWrapper>
          <Tabs
            renderTabBar={() => <ScrollableInkTabBar style={{ textAlign : "center"}} />}
            renderTabContent={() => <TabContent animated={false} />}
          >
            {servicesOrProducts && servicesOrProducts.map((tabItem, index) => (
              
              <TabPane
                tab={
                  <Button
                    content={tabItem.name}
                    data-text={tabItem.name}
                    title={tabItem.name}
                  />
                }
                key={index + 1}
              >
               
               <PortfolioShowcaseItem>
                          <Box {...portfolioImage}>
                            <Image
                              fluid={
                                (tabItem.featuredImage !== null) | undefined
                                  ? tabItem.featuredImage.fluid
                                  : {}
                              }
                              src={tabItem.featuredImage.fluid.src}
                              alt={`PortfolioImage-${index + 1}`}
                            />
                          </Box>
                          <Box {...portfolioDetails}>
                            {/*<PortfolioLink>
                              <a href={portfolioItem.link || '#'}>
                                VISIT LIVE SITE
                              </a>
                            </PortfolioLink>*/}
                            <Heading
                              content={tabItem.name}
                              {...titleStyle}
                            />     
                              <Text content={tabItem.description.childMarkdownRemark.html.replace(/(<([^>]+)>)/gi, "")} {...detailsStyle} />                      
                              {/*<Text
                                dangerouslySetInnerHTML={{
                                  __html: tabItem.description.childMarkdownRemark.html,
                                }}
                                {...detailsStyle}
                              />*/}
                              
                              {tabItem.priceDescription &&
                                <BuiltWith>
                                    <span>
                                        {tabItem.priceDescription}
                                    </span>
                                </BuiltWith>}
                            {/*{portfolioItem.buildWith ? (
                              <BuiltWith>
                                {portfolioItem.buildWith.map((item, index) => (
                                  <span key={`buildWith-item-${index}`}>
                                    {item.content}
                                  </span>
                                ))}
                              </BuiltWith>
                            ) : (
                              ''
                            )}*/}
                          </Box>

                          {/*{portfolioItem.featuredIn ||
                          portfolioItem.view ||
                          portfolioItem.love ||
                          portfolioItem.feedback ? (
                            <PortfolioMeta>
                              {portfolioItem.featuredIn ? (
                                <MetaItem className="meta_featured">
                                  FEATURED IN
                                  <a href={portfolioItem.featuredLink || '#'}>
                                    {portfolioItem.featuredIn}
                                  </a>
                                </MetaItem>
                              ) : (
                                ''
                              )}
                              {portfolioItem.view ? (
                                <MetaItem>
                                  <b>{portfolioItem.view}</b> View
                                </MetaItem>
                              ) : (
                                ''
                              )}
                              {portfolioItem.love ? (
                                <MetaItem>
                                  <b>{portfolioItem.love}</b> Love
                                </MetaItem>
                              ) : (
                                ''
                              )}
                              {portfolioItem.feedback ? (
                                <MetaItem>
                                  <b>{portfolioItem.feedback}</b> Feedback
                                </MetaItem>
                              ) : (
                                ''
                              )}
                            </PortfolioMeta>
                          ) : (
                            ''
                          )}*/}
                        </PortfolioShowcaseItem>
              </TabPane>
            ))}
          </Tabs>
        </PortfolioShowcaseWrapper>
      </Container>
    </SectionWrapper>
  );
};

Services.propTypes = {
  portfolioImage: PropTypes.object,
  portfolioDetails: PropTypes.object,
  titleStyle: PropTypes.object,
  detailsStyle: PropTypes.object,
};

Services.defaultProps = {  
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px', '36px', '36px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    ml: 'auto',
    mr: 'auto',
    lineHeight: '1.12',
    width: '500px',
    maxWidth: '100%',
  },
  portfolioImage: {
    width: [1, 1, 1 / 2],
  },
  portfolioDetails: {
    width: [1, 1, 1 / 2],
    p: ['30px 0 0 0', '40px 0 0 0', '0 0 0 30px', '0 50px', '0 50px'],
  },
  titleStyle: {
    fontSize: ['22px', '22px', '26px', '40px', '40px'],
    fontWeight: '600',
    color: '#302b4e',
    mb: '17px',
  },
  detailsStyle: {
    fontSize: ['15px', '15px', '15px', '16px', '16px'],
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0',
  },
};

export default Services;
